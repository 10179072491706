import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    text: { secondary: grey[500] },
    primary: { main: '#998DF7', contrastText: grey[50] },
    secondary: { main: grey[50] },
    background: {
      default: 'rgba(0, 0, 0, 0)',
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained' },
          style: { color: grey[50] },
        },
      ],
    },
    MuiToolbar: {
      styleOverrides: {
        root: { color: grey[50] },
      },
    },
  },
});

export default theme;
