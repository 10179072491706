import { User } from '@firebase/auth';
import { isAdminEmail } from 'cogsFirestore';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

// We do not use Firebase Analytics `logEvent(...)`, etc. here
// because `gtag('event', ...)`, etc. does the same thing
// and sends data to all configured sources

export type AnalyticsEvent =
  | 'login'
  | 'sign_up'
  | 'logout'
  | 'begin_checkout'
  | 'view_item_list'
  | 'update_purchase'
  | 'cancel_subscription';

export function logAnalyticsEvent(event: AnalyticsEvent) {
  if (typeof window === 'undefined') return;

  gtag('event', event);
}

export function setUserAnalyticsProperties(user: User | null) {
  if (typeof window === 'undefined') return;

  gtag('set', { user_id: user?.uid ?? '' });
  gtag('set', 'user_properties', {
    is_admin: user?.email ? isAdminEmail(user.email) : false,
  });
}

/**
 * Logs `page_view` events when navigating client-side
 */
export function usePageViewAnalytics() {
  const router = useRouter();

  useEffect(() => {
    gtag('event', 'page_view', {
      page_path: router.asPath,
      page_title: document.title,
      page_location: document.location.href,
    });
  }, [router.asPath]);
}

export const GTAG_SETTINGS = {
  linker: {
    accept_incoming: true,
    domains: ['cogs.show'],
  },
};
