import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';
import cogsTextLogo from '../images/cogs_text.svg';
import { ENVIRONMENT } from '../utils/firebase/environment';
import { COGS_HOME_URL } from '../utils/routes';

export default function CogsBanner() {
  return (
    <Box textAlign="center" margin="50px 0">
      <Link href={COGS_HOME_URL} underline="hover">
        <img alt="COGS" src={cogsTextLogo.src} style={{ width: 300 }} />
      </Link>
      {ENVIRONMENT === 'test' && (
        <Typography variant="body1" color="secondary" style={{ marginTop: '1em', opacity: 0.5 }}>
          TEST MODE
        </Typography>
      )}
    </Box>
  );
}
