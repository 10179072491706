import { Container } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { useCallback, useState } from 'react';
import { logout, sendEmailVerification, useLoggedInUser } from '../utils/firebase/auth';
import CogsBanner from './CogsBanner';

export default function EmailVerification() {
  const loggedInUser = useLoggedInUser();

  const [emailSending, setEmailSending] = useState(false);
  const [emailError, setEmailError] = useState<string | null>(null);

  const sendNewEmailVerification = useCallback(async () => {
    setEmailSending(true);
    setEmailError(null);
    try {
      await sendEmailVerification();
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } catch (error) {
      setEmailError((error as Error).message || (error as object).toString());
    } finally {
      setEmailSending(false);
    }
  }, []);

  return (
    <Container maxWidth="sm">
      <CogsBanner />
      {/* Note: An email has already been set when the account is created so we show the banner by default */}
      {emailError ? (
        <Alert severity="error">
          Error sending email to <code>{loggedInUser?.email}</code>: {emailError}
        </Alert>
      ) : emailSending ? (
        <Alert severity="info">
          Sending email to <code>{loggedInUser?.email}</code>
        </Alert>
      ) : (
        <Alert severity="success">
          Email verification link sent to <code>{loggedInUser?.email}</code>
        </Alert>
      )}
      <Paper
        sx={{
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginY: 2,
        }}
      >
        <Typography marginY={2}>
          Please check your email and click the verification link to continue
        </Typography>
        <Button color="primary" disabled={emailSending} onClick={sendNewEmailVerification}>
          Resend verification email
        </Button>
        <Button color="primary" onClick={logout}>
          Logout
        </Button>
      </Paper>
    </Container>
  );
}
