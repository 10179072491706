import { User as FirebaseUser } from '@firebase/auth';
import { DocumentReference } from '@firebase/firestore';
import {
  FirewardGetOutput,
  Installation,
  isAdminUser,
  LicenseKey,
  Order,
  orderFromShopify,
  orderFromSquarespace,
  orderShouldBeIgnored,
  StripeSubscription,
  User as FirestoreUser,
  userIdFromSubcollectionRef,
  UserNotes,
} from 'cogsFirestore';
import { InstallationStatusUpdate } from 'cogsRealtimeDb';
import { Dictionary, fromPairs, groupBy } from 'lodash';
import React, { useContext, useEffect, useMemo } from 'react';
import { useLoggedInUser } from '../utils/firebase/auth';
import {
  useAllCogsInstallations,
  useAllCogsLicenseKeys,
  useAllCogsSubscriptions,
  useAllShopifyOrders,
  useAllSquarespaceOrders,
  useAllUserNotes,
  useFirestoreUsers,
  useSignUpSurveyResponses,
} from '../utils/firebase/firestore';
import { useAuthUserList } from '../utils/firebase/functions';
import { useAllInstallationStatuses, useInstallationStatuses } from '../utils/firebase/realtimeDb';

export interface AdminData {
  loggedInUser?: ReturnType<typeof useLoggedInUser>;
  users?: ReturnType<typeof useAuthUserList>;
  usersById?: Dictionary<FirebaseUser>;
  firestoreUsers?: ReturnType<typeof useFirestoreUsers>;
  firestoreUsersById?: Dictionary<FirestoreUser<FirewardGetOutput>>;
  adminUserIds: ReadonlySet<string>;
  userNotes?: Dictionary<UserNotes<FirewardGetOutput>>;
  installations?: ReturnType<typeof useAllCogsInstallations>;
  installationsByUserId?: Dictionary<
    [string, Installation<FirewardGetOutput>, DocumentReference][]
  >;
  installationStatuses?: ReturnType<typeof useAllInstallationStatuses>;
  installationStatusesList?: (InstallationStatusUpdate & {
    userId: string;
    installationId: string;
  })[];
  licenseKeys?: ReturnType<typeof useAllCogsLicenseKeys>;
  licenseKeysByUserId?: Dictionary<[string, LicenseKey<FirewardGetOutput>, DocumentReference][]>;
  subscriptions?: ReturnType<typeof useAllCogsSubscriptions>;
  subscriptionsByUserId?: Dictionary<
    [string, StripeSubscription<FirewardGetOutput>, DocumentReference][]
  >;
  signUpSurveyResponses?: ReturnType<typeof useSignUpSurveyResponses>;
  orders?: Order[];
  /**
   * Includes internal orders
   */
  allOrders?: Order[];
  hasLoaded: boolean;
}

const AdminDataContext = React.createContext<AdminData>({
  adminUserIds: new Set(),
  hasLoaded: false,
});

export function useAdminData() {
  return useContext(AdminDataContext);
}

export default function AdminDataProvider({ children }: { children?: React.ReactNode }) {
  const loggedInUser = useLoggedInUser();

  const users = useAuthUserList();
  const usersById = useMemo(
    () => fromPairs(users?.map((user) => [user.uid, user] as [string, FirebaseUser])),
    [users]
  );

  const firestoreUsers = useFirestoreUsers();
  const firestoreUsersById = useMemo(() => fromPairs(firestoreUsers), [firestoreUsers]);

  const adminUserIds = useMemo(
    () => new Set(users?.filter(isAdminUser).map((user) => user.uid)),
    [users]
  );

  const installations = useAllCogsInstallations();
  const installationsByUserId = useMemo(
    () => groupBy(installations, ([, , ref]) => userIdFromSubcollectionRef(ref)),
    [installations]
  );

  const installationStatuses = useAllInstallationStatuses();
  const installationStatusesList = useMemo(
    () =>
      installationStatuses !== undefined
        ? Object.entries(installationStatuses ?? {}).flatMap(([userId, userData]) =>
            Object.entries(userData.installations).map(([installationId, installationStatus]) => ({
              ...installationStatus,
              userId,
              installationId,
            }))
          )
        : undefined,
    [installationStatuses]
  );

  const licenseKeys = useAllCogsLicenseKeys();
  const licenseKeysByUserId = useMemo(
    () => groupBy(licenseKeys, ([, , ref]) => userIdFromSubcollectionRef(ref)),
    [licenseKeys]
  );

  const subscriptions = useAllCogsSubscriptions();
  const subscriptionsByUserId = useMemo(
    () => groupBy(subscriptions, ([, , ref]) => userIdFromSubcollectionRef(ref)),
    [subscriptions]
  );

  const allSignUpSurveyResponses = useSignUpSurveyResponses(-Infinity);
  const signUpSurveyResponses = useMemo(
    () =>
      allSignUpSurveyResponses?.filter(({ response }) =>
        Boolean(response.howDidYouHearAboutCogs.trim())
      ),
    [allSignUpSurveyResponses]
  );

  const userNotes = useAllUserNotes();

  const squarespaceOrdersWithId = useAllSquarespaceOrders();
  const squarespaceOrders = useMemo(
    () => squarespaceOrdersWithId?.map(([, order]) => order),
    [squarespaceOrdersWithId]
  );

  const shopifyOrdersWithId = useAllShopifyOrders();
  const shopifyOrders = useMemo(
    () => shopifyOrdersWithId?.map(([, order]) => order),
    [shopifyOrdersWithId]
  );

  const allOrders = useMemo(
    () =>
      squarespaceOrders && shopifyOrders
        ? [
            ...squarespaceOrders.map(orderFromSquarespace),
            ...shopifyOrders.map(orderFromShopify),
          ].sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
        : undefined,
    [squarespaceOrders, shopifyOrders]
  );

  const orders = useMemo(
    () => allOrders?.filter((order) => !orderShouldBeIgnored(order)),
    [allOrders]
  );

  const context: AdminData = {
    loggedInUser,
    users,
    usersById,
    firestoreUsers,
    firestoreUsersById,
    adminUserIds,
    userNotes,
    installations,
    installationsByUserId,
    installationStatuses,
    installationStatusesList,
    licenseKeys,
    licenseKeysByUserId,
    subscriptions,
    subscriptionsByUserId,
    signUpSurveyResponses,
    allOrders,
    orders,
    hasLoaded: false,
  };

  // Allow data to be access on the Javascript console
  useEffect(() => {
    (window as any).COGS = context;
    return () => {
      delete (window as any).COGS;
    };
  });

  if (!Object.values(context).includes(undefined)) {
    context.hasLoaded = true;
  }

  return <AdminDataContext.Provider value={context}>{children}</AdminDataContext.Provider>;
}
