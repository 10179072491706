import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';
import { defaultPath, Path, pathLabel, staticPaths } from '../utils/routes';
import {
  CogsLogo,
  ProfileTabContent,
  SupportTabContent,
  TabSpacer,
  useTabStyle,
} from './MainToolbar';

function BackToHomeTabContent() {
  return (
    <Box display="flex" alignItems="center">
      <ArrowBackIcon fontSize="large" style={{ marginRight: '0.25em' }} />
      <CogsLogo />
    </Box>
  );
}

function AdminTabs() {
  const router = useRouter();
  const tabStyle = useTabStyle();
  const onChange = useCallback(
    (event: React.ChangeEvent<{}>, pathname: string) => {
      event.preventDefault();
      router.push(pathname);
    },
    [router]
  );

  const currentPath = router.pathname === '/' ? defaultPath : router.pathname + '/';

  return (
    <Tabs
      style={{ flexGrow: 1, alignSelf: 'flex-end' }}
      value={(staticPaths as ReadonlySet<string>).has(currentPath) ? currentPath : false}
      onChange={onChange}
      textColor="inherit"
      indicatorColor="secondary"
      variant="scrollable"
    >
      <Tab
        style={{ ...tabStyle, opacity: 1 }}
        label={<BackToHomeTabContent />}
        href="/"
        value="/"
      />
      <TabSpacer />
      <Tab style={tabStyle} label={pathLabel(Path.Stats)} value={Path.Stats} />
      <Tab style={tabStyle} label={pathLabel(Path.Users)} value={Path.Users} />
      <Tab style={tabStyle} label={pathLabel(Path.Orders)} value={Path.Orders} />
      <Tab
        style={tabStyle}
        label={pathLabel(Path.AllInstallations)}
        value={Path.AllInstallations}
      />
      <Tab style={tabStyle} label={pathLabel(Path.AllLicenses)} value={Path.AllLicenses} />
      <TabSpacer />
      <Tab style={tabStyle} label={<SupportTabContent />} value={Path.Support} />
      <Tab
        style={{ ...tabStyle, paddingRight: '2em' }}
        label={<ProfileTabContent />}
        value={Path.Profile}
      />
    </Tabs>
  );
}

export default function AdminToolbar() {
  return (
    <AppBar position="static">
      <Toolbar disableGutters>
        <AdminTabs />
      </Toolbar>
    </AppBar>
  );
}
