import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import Placeholder from './Placeholder';

export default function Loading({ style }: { style?: React.CSSProperties }) {
  return (
    <Placeholder {...{ style }}>
      <CircularProgress />
    </Placeholder>
  );
}
