export enum Path {
  Installations = '/installations/',
  Licenses = '/licenses/',
  Users = '/admin/users/',
  Orders = '/admin/orders/',
  Profile = '/profile/',
  Login = '/login/',
  VerifyEmail = '/verify-email/',
  Signup = '/signup/',
  InstallationStats = '/admin/stats/installations/',
  Stats = '/admin/stats/',
  AllLicenses = '/admin/licenses/',
  AllInstallations = '/admin/installations/',
  Admin = '/admin/',
  ResetPassword = '/reset-password/',
  Download = '/download/',
  Support = 'https://cogs.show/support',
}

export const staticPaths: ReadonlySet<Path> = new Set(Object.values(Path));

export function isAdminPath(path: string) {
  return path.startsWith('/admin/');
}

export function pathLabel(path: Path): string {
  switch (path) {
    case Path.Installations:
    case Path.AllInstallations:
    case Path.InstallationStats:
      return 'Installations';
    case Path.Licenses:
    case Path.AllLicenses:
      return 'Licenses';
    case Path.Users:
      return 'Users';
    case Path.Profile:
      return 'Profile';
    case Path.Login:
      return 'Login';
    case Path.VerifyEmail:
      return 'Verify Email';
    case Path.Signup:
      return 'Signup';
    case Path.Admin:
      return 'Admin';
    case Path.Stats:
      return 'Stats';
    case Path.ResetPassword:
      return 'Reset Password';
    case Path.Download:
      return 'Download';
    case Path.Support:
      return 'Support';
    case Path.Orders:
      return 'Orders';
  }
}

export function userAdminPath(userId: string) {
  return Path.Users + encodeURIComponent(userId) + '/';
}

export function userInstallationsAdminPath(userId: string) {
  return Path.Installations + '?uid=' + encodeURIComponent(userId);
}

export function userLicenseKeysAdminPath(userId: string) {
  return Path.Licenses + '?uid=' + encodeURIComponent(userId);
}

export const defaultPath = Path.Installations;
export const COGS_HOME_URL = 'https://cogs.show';
export const backendHomeUrl = () =>
  `${window.location.protocol}//${window.location.host}${defaultPath}`;

export const EULA_URL = 'https://cogs.show/eula';
export const PRIVACY_POLICY_URL = 'https://cogs.show/privacy';

export const INSTALLATIONS_ONLINE_NOW =
  Path.AllInstallations +
  '?filter=%7B%22items%22%3A%5B%7B%22columnField%22%3A%22online%22%2C%22id%22%3A59270%2C%22operatorValue%22%3A%22is%22%2C%22value%22%3A%22true%22%7D%5D%7D';

export const USERS_SUBSCRIPTIONS =
  Path.Users +
  '?sort=%5B%7B%22field%22%3A%22subscriptionsTotal%22%2C%22sort%22%3A%22desc%22%7D%5D&filter=%7B%22items%22%3A%5B%7B%22columnField%22%3A%22licenseKeys%22%2C%22id%22%3A85005%2C%22operatorValue%22%3A%22%3E%22%2C%22value%22%3A%220%22%7D%5D%7D';

export const USERS_INSTALLATIONS =
  Path.AllInstallations + '?sort=%5B%7B%22field%22%3A%22version%22%2C%22sort%22%3A%22desc%22%7D%5D';
