export const ENVIRONMENT =
  process.env.NEXT_PUBLIC_FIREBASE_ENV === 'production' ? 'production' : 'test';

export const EMULATOR_HOST =
  typeof window !== 'undefined' ? process.env.NEXT_PUBLIC_EMULATOR_HOST : undefined;

export const HOST_URL = EMULATOR_HOST
  ? `http://localhost:${process.env.PORT || 3000}`
  : ENVIRONMENT === 'test'
  ? 'https://account-test.cogs.show'
  : 'https://account.cogs.show';
