// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { ENVIRONMENT } from './utils/firebase/environment';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: SENTRY_DSN || 'https://86a049a766f94cffa624854f29faa6a5@o452091.ingest.sentry.io/5446993',
    integrations: [new TracingIntegrations.BrowserTracing()],
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    environment: ENVIRONMENT,
  });
}
