import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as Sentry from '@sentry/nextjs';
import dayjs from 'dayjs';
import dayjsPluginRelativeTime from 'dayjs/plugin/relativeTime';
import { AppPropsType } from 'next/dist/shared/lib/utils';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import AdminToolbar from '../components/AdminToolbar';
import EmailVerification from '../components/EmailVerification';
import Loading from '../components/Loading';
import MainToolbar from '../components/MainToolbar';
import UserOverride from '../components/UserOverride';
import AdminDataProvider from '../providers/AdminDataProvider';
import UserDataProvider from '../providers/UserDataProvider';
import '../styles.css';
import { usePageViewAnalytics } from '../utils/firebase/analytics';
import { useLoggedInUser } from '../utils/firebase/auth';
import { HOST_URL } from '../utils/firebase/environment';
import { defaultPath, isAdminPath, Path, pathLabel, userAdminPath } from '../utils/routes';
import theme from '../utils/theme';

dayjs.extend(dayjsPluginRelativeTime);

LicenseInfo.setLicenseKey(
  '08e78346bce92573de8f25cc295fad7aT1JERVI6MzU4MTcsRVhQSVJZPTE2NzM2MDk4NDYwMDAsS0VZVkVSU0lPTj0x'
);

function App({ Component, pageProps }: AppPropsType) {
  const user = useLoggedInUser();
  const router = useRouter();
  const path = router.pathname === '/' ? defaultPath : router.pathname + '/';
  const title = pathLabel(path as Path) as string | undefined;

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: user?.uid,
          email: user?.email ?? undefined,
          profileUrl: user ? HOST_URL + userAdminPath(user.uid) : undefined,
        });
      });
    }
  }, [user]);

  // Redirect from `#UID` to `/users/UID`
  useEffect(() => {
    if (window.location.hash) {
      router.replace(userAdminPath(window.location.hash.replace(/^#/, '')));
    }
  }, [router]);

  usePageViewAnalytics();

  return (
    <>
      <Head>
        <link rel="icon" href="/logo192.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Manage your COGS account, license keys, and installations"
        />
        <link rel="apple-touch-icon" href="/logo192.png" />
        <link rel="manifest" href="/manifest.json" />
        <title>{(title ? `${title} - ` : '') + 'COGS Account'}</title>
      </Head>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {user === undefined ? (
              <Loading />
            ) : user && !user.emailVerified ? (
              <EmailVerification />
            ) : (
              <>
                {user != null ? (
                  <UserDataProvider>
                    {isAdminPath(router.pathname) ? (
                      <AdminDataProvider>
                        <AdminToolbar />
                        <Component {...pageProps} />
                      </AdminDataProvider>
                    ) : (
                      <>
                        <MainToolbar />
                        <UserOverride />
                        <Component {...pageProps} />
                      </>
                    )}
                  </UserDataProvider>
                ) : (
                  <Component {...pageProps} />
                )}
              </>
            )}
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </>
  );
}

export default App;
