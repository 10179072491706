import BuildIcon from '@mui/icons-material/Build';
import HelpIcon from '@mui/icons-material/Help';
import PersonIcon from '@mui/icons-material/Person';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Tab, { TabProps } from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useRouter } from 'next/router';
import React, { useCallback, useMemo } from 'react';
import useIsLargeScreen from '../hooks/useIsLargeScreen';
import cogsTextLogo from '../images/cogs_text.svg';
import cogsCLogo from '../images/just_the_c.svg';
import { useIsLoggedInAsAdminUser, useLoggedInUser } from '../utils/firebase/auth';
import { ENVIRONMENT } from '../utils/firebase/environment';
import {
  COGS_HOME_URL,
  defaultPath,
  isAdminPath,
  Path,
  pathLabel,
  staticPaths,
} from '../utils/routes';

export function CogsLogo() {
  const isLargeScreen = useIsLargeScreen();
  const router = useRouter();

  const secondaryText = [
    ENVIRONMENT === 'test' && `TEST${isLargeScreen ? ' MODE' : ''}`,
    isAdminPath(router.pathname) && 'ADMIN',
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginTop="0.5em"
      marginLeft="0.5em"
    >
      <Typography variant="h6">
        <img
          alt="COGS"
          src={isLargeScreen ? cogsTextLogo.src : cogsCLogo.src}
          style={{ width: isLargeScreen ? 100 : 30, margin: 0 }}
        />
      </Typography>
      {secondaryText && (
        <Typography
          variant="caption"
          color="secondary"
          style={{ margin: '-0.5em 0', whiteSpace: 'nowrap' }}
        >
          {secondaryText}
        </Typography>
      )}
    </Box>
  );
}

export function SupportTabContent() {
  const user = useLoggedInUser();
  const isLargeScreen = useIsLargeScreen();

  return (
    <Tooltip title="Support">
      <Box display="flex" alignItems="center">
        <HelpIcon />
        {isLargeScreen && <span style={{ marginLeft: '1em' }}>Support</span>}
      </Box>
    </Tooltip>
  );
}

export function ProfileTabContent() {
  const user = useLoggedInUser();
  const isLargeScreen = useIsLargeScreen();

  return (
    <Tooltip title={user ? `Signed in as ${user.displayName} (${user.email})` : ''}>
      <Box display="flex" alignItems="center">
        <PersonIcon />
        {isLargeScreen && <span style={{ marginLeft: '1em' }}>Profile</span>}
      </Box>
    </Tooltip>
  );
}

export function AdminTabContent() {
  const isLargeScreen = useIsLargeScreen();

  return (
    <Tooltip title="Stats & Admin">
      <Box display="flex" alignItems="center">
        <BuildIcon />
        {isLargeScreen && <span style={{ marginLeft: '1em' }}>Admin</span>}
      </Box>
    </Tooltip>
  );
}

function UserTabs() {
  const router = useRouter();
  const isAdminUser = useIsLoggedInAsAdminUser();
  const tabStyle = useTabStyle();
  const onChange = useCallback(
    (event: React.ChangeEvent<{}>, pathname: string) => {
      event.preventDefault();
      router.push({ pathname, ...(!isAdminPath(pathname) && { query: router.query }) });
    },
    [router]
  );

  const currentPath = router.pathname === '/' ? defaultPath : router.pathname + '/';

  return (
    <Tabs
      style={{ flexGrow: 1, alignSelf: 'flex-end' }}
      value={(staticPaths as ReadonlySet<string>).has(currentPath) ? currentPath : false}
      onChange={onChange}
      textColor="inherit"
      indicatorColor="secondary"
      variant="scrollable"
    >
      <Tab
        style={{ ...tabStyle, opacity: 1 }}
        label={<CogsLogo />}
        href={COGS_HOME_URL}
        value={COGS_HOME_URL}
      />
      <TabSpacer />
      <Tab style={tabStyle} label={pathLabel(Path.Installations)} value={Path.Installations} />
      <Tab style={tabStyle} label={pathLabel(Path.Licenses)} value={Path.Licenses} />
      <TabSpacer />
      <OptionalTab
        style={tabStyle}
        label={<AdminTabContent />}
        value={Path.Admin}
        hidden={!isAdminUser}
      />
      <Tab style={tabStyle} label={<SupportTabContent />} value={Path.Support} />
      <Tab
        style={{ ...tabStyle, paddingRight: '2em' }}
        label={<ProfileTabContent />}
        value={Path.Profile}
      />
    </Tabs>
  );
}

export function OptionalTab(props: TabProps) {
  return (
    <Tab {...props} style={{ ...props.style, ...(props.hidden ? { display: 'none' } : {}) }} />
  );
}

export function TabSpacer() {
  return (
    <Tab
      disabled
      style={{ visibility: 'hidden', flexGrow: 0.5, padding: 0, minWidth: 0, maxWidth: 'none' }}
    />
  );
}

export function useTabStyle() {
  const isLargeScreen = useIsLargeScreen();

  return useMemo(
    () => ({
      minHeight: 64,
      minWidth: 'fit-content',
      paddingLeft: isLargeScreen ? '2em' : '1em',
      paddingRight: isLargeScreen ? '2em' : '1em',
    }),
    [isLargeScreen]
  );
}

export default function MainToolbar() {
  return (
    <AppBar position="static">
      <Toolbar disableGutters>
        <UserTabs />
      </Toolbar>
    </AppBar>
  );
}
