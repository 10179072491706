import PersonIcon from '@mui/icons-material/Person';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { useUserData } from '../providers/UserDataProvider';
import { useLoggedInUser } from '../utils/firebase/auth';
import { useAuthUser } from '../utils/firebase/functions';
import { userAdminPath } from '../utils/routes';

export default function UserOverride() {
  const { userId, isLoggedInAsAdminUser } = useUserData();
  const loggedInUser = useLoggedInUser();
  const router = useRouter();

  if (!isLoggedInAsAdminUser || !loggedInUser || !userId || userId === loggedInUser.uid) {
    return null;
  }

  return (
    <Container maxWidth="sm" style={{ paddingTop: '2em' }}>
      <Alert severity="warning" onClose={() => router.push(router.pathname)}>
        <UserInfo userId={userId} />
      </Alert>
    </Container>
  );
}

function UserInfo({ userId }: { userId: string }) {
  const user = useAuthUser(userId);

  return (
    <div style={{ display: 'flex', flexGrow: 1, alignItems: 'center' }}>
      <PersonIcon style={{ marginLeft: 12, marginRight: 12 }} />
      <Typography variant="body1">
        Viewing as <Link href={userAdminPath(userId)}>{user?.displayName ?? '...'}</Link>
      </Typography>
    </div>
  );
}
