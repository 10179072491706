import { isAdminUser } from 'cogsFirestore';
import {
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  getAuth,
  onAuthStateChanged,
  reauthenticateWithCredential,
  sendEmailVerification as sendFirebaseEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  User,
} from 'firebase/auth';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { backendHomeUrl, Path } from '../routes';
import { logAnalyticsEvent, setUserAnalyticsProperties } from './analytics';
import { EMULATOR_HOST } from './environment';
import firebaseApp, { EMULATOR_PORTS } from './firebaseApp';

const auth = getAuth(firebaseApp);

if (EMULATOR_HOST) {
  connectAuthEmulator(auth, `http://${EMULATOR_HOST}:${EMULATOR_PORTS.auth}/`);
}

onAuthStateChanged(auth, (user) => {
  setUserAnalyticsProperties(user);
});

export async function createUser(email: string, password: string, displayName: string) {
  const credential = await createUserWithEmailAndPassword(auth, email.toLowerCase(), password);
  if (credential.user) {
    await updateProfile(credential.user, { displayName });
    await sendFirebaseEmailVerification(credential.user);
  }
  logAnalyticsEvent('sign_up');
  return credential;
}

export async function signIn(email: string, password: string) {
  const credential = auth.currentUser
    ? reauthenticateWithCredential(auth.currentUser, EmailAuthProvider.credential(email, password))
    : signInWithEmailAndPassword(auth, email, password);
  logAnalyticsEvent('login');
  return credential;
}

export function sendPasswordReset(email: string) {
  return sendPasswordResetEmail(auth, email, { url: backendHomeUrl() });
}

export function useLoggedInUser() {
  const [user, setUser] = useState<User | null>();
  const [, forceRerender] = useState({});

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, setUser);
    return unsubscribe;
  }, []);

  // Keep refreshing user email if not yet verified
  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    async function refreshUserPeriodicallyUntil(until: (user: User | null) => boolean) {
      try {
        await Promise.all([
          auth.currentUser?.reload(),
          auth.currentUser?.getIdToken(true), // Force server to acknowledge new user state
        ]);
      } catch (error) {
        console.warn(error);
      }
      if (until(auth.currentUser)) {
        // Reload gives us the same User object with new properties so force re-render
        if (timeout) {
          // If timeout has been cleared this hook is no longer mounted so we don't want to rerender
          forceRerender({});
        }
        return;
      }
      timeout = setTimeout(() => refreshUserPeriodicallyUntil(until), 5000);
    }

    if (!user?.emailVerified) {
      refreshUserPeriodicallyUntil((user) => Boolean(user?.emailVerified));
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
    };
  }, [user]);

  return user;
}

export function useIsLoggedInAsAdminUser() {
  const user = useLoggedInUser();
  return user ? isAdminUser(user) : false;
}

export function useUserIdWithQueryOverride() {
  const router = useRouter();
  const loggedInUser = useLoggedInUser();
  return (router.query.uid as string | undefined) || loggedInUser?.uid;
}

export function useLoginRequired() {
  const router = useRouter();
  const loggedInUser = useLoggedInUser();
  if (loggedInUser === null) {
    router.replace({
      pathname: Path.Login,
      query: { continue: router.asPath },
    });
  } else if (loggedInUser && !loggedInUser.emailVerified) {
    router.replace({
      pathname: Path.VerifyEmail,
      query: { continue: router.asPath },
    });
  }
}

export function logout() {
  signOut(auth);
  logAnalyticsEvent('logout');
}

export function sendEmailVerification() {
  return new Promise<void>((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user !== undefined) {
        unsubscribe();
        if (user) {
          sendFirebaseEmailVerification(
            user,
            window.location.protocol === 'https' ? { url: `https://${window.location.host}` } : null
          )
            .then(resolve)
            .catch(reject);
        } else {
          reject(new Error('Not signed in'));
        }
      }
    });
  });
}

export function currentUser() {
  return auth.currentUser;
}
