import {
  connectDatabaseEmulator,
  DatabaseReference,
  DataSnapshot,
  getDatabase,
  onValue,
  ref,
} from 'firebase/database';
import { useEffect, useMemo, useState } from 'react';
import { EMULATOR_HOST } from './environment';
import firebaseApp, { EMULATOR_PORTS } from './firebaseApp';

const database = getDatabase(firebaseApp);

if (EMULATOR_HOST) {
  connectDatabaseEmulator(database, EMULATOR_HOST, EMULATOR_PORTS.realtimedb);
}

export type InstallationStatus =
  | 'project closed'
  | 'setup'
  | 'pre-show'
  | 'in progress'
  | 'finished';

export interface InstallationStatusUpdate {
  status?: InstallationStatus;
  updated: number;
}

function useRealtimeDbValue<Value>(ref: DatabaseReference | undefined) {
  const [value, setValue] = useState<Value>();

  useEffect(() => {
    if (!ref) {
      setValue(undefined);
      return;
    }

    const listener = (snapshot: DataSnapshot) => setValue(snapshot.val() as Value);
    return onValue(ref, listener);
  }, [ref]);

  return value;
}

function installationStatusesRealtimeDbRef(userId: string) {
  return ref(database, `users/${userId}/installations`);
}

export function useInstallationStatuses(userId: string | undefined) {
  const ref = useMemo(
    () => (userId ? installationStatusesRealtimeDbRef(userId) : undefined),
    [userId]
  );
  return useRealtimeDbValue<{ [installationId: string]: InstallationStatusUpdate } | null>(ref);
}

const allInstallationsRef = ref(database, `users`);

export function useAllInstallationStatuses() {
  const installationStatuses = useRealtimeDbValue<{
    [userId: string]: { installations: { [installationId: string]: InstallationStatusUpdate } };
  } | null>(allInstallationsRef);

  return installationStatuses;
}
