import { DocumentReference } from '@firebase/firestore';
import { initializeApp } from 'firebase/app';
import { ENVIRONMENT } from './environment';

const firebaseConfig =
  ENVIRONMENT === 'production'
    ? {
        apiKey: 'AIzaSyB4vGNdk-jNIPTLeWqZcsWj2raGuEtZavY',
        authDomain: 'cogs-backend.firebaseapp.com',
        databaseURL: 'https://cogs-backend.firebaseio.com',
        projectId: 'cogs-backend',
        storageBucket: 'cogs-backend.appspot.com',
        messagingSenderId: '874889110225',
        appId: '1:874889110225:web:f39b8ec984d7b71a7c1bf0',
        measurementId: 'G-E282055FNW',
      }
    : {
        apiKey: 'AIzaSyCQYYtw6drI08iR6D0Op1j0KaJXMhhlWUU',
        authDomain: 'cogs-backend-test.firebaseapp.com',
        databaseURL: 'https://cogs-backend-test-default-rtdb.europe-west1.firebasedatabase.app',
        projectId: 'cogs-backend-test',
        storageBucket: 'cogs-backend-test.appspot.com',
        messagingSenderId: '753054722541',
        appId: '1:753054722541:web:c3c7e28854abd662c25fe4',
        measurementId: 'G-GQQ6HKKC2E',
      };

export const GOOGLE_ANALYTICS_MEASUREMENT_ID =
  ENVIRONMENT === 'production' ? 'UA-163988231-1' : null;

export const FIREBASE_ANALYTICS_MEASUREMENT_ID = firebaseConfig.measurementId;

export const EMULATOR_PORTS = {
  ui: 13000,
  functions: 13001,
  firestore: 13002,
  auth: 13003,
  realtimedb: 13005,
} as const;

const firebaseApp = initializeApp(firebaseConfig);

export function firestoreConsoleUrl(ref: DocumentReference): string {
  if (process.env.NEXT_PUBLIC_EMULATOR_HOST) {
    return `http://${process.env.NEXT_PUBLIC_EMULATOR_HOST}:${
      EMULATOR_PORTS.ui
    }/firestore/data/${ref.path.split('/').map(encodeURIComponent).join('/')}`;
  } else {
    return `https://console.firebase.google.com/project/${
      firebaseConfig.projectId
    }/firestore/data/${`/${ref.path}`.replace(/\//g, '~2F')}`;
  }
}

export default firebaseApp;
